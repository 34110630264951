<script>
import { required } from "vuelidate/lib/validators";
import { userService } from "../../../helpers/user.service";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      Users: userService.getUsers().then((data) => {
        this.Users = data.message;
      }),
      userRoles: userService.getRoles().then((data) => {
        this.userRoles = data.message;
      }),
      selected: [],
      NewUser: {
        fullname: "",
        phone: "",
        password: "",
        user_role: "",
      },
      submitted: false,
      showmodal: false,
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
      Cuser_id: JSON.parse(localStorage.getItem("auth")).id,
    };
  },
  validations: {
    NewUser: {
      fullname: { required },
      phone: { required },
      password: { required },
      user_role: { required },
    },
  },
  methods: {
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    DeleteUser(userId) {
      this.swalWithBootstrapButtons
        .fire({
          title: "آیا مطمئن هستید ؟",
          text: "شما دیگر نمی‌توانید این را برگردانید",
          icon: "warning",
          confirmButtonText: "حذف",
          cancelButtonText: "لغو",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            userService.deleteUser(userId).then((data) => {
              this.swalWithBootstrapButtons.fire({
                text: data.message,
                icon: data.err,
                confirmButtonText: "تایید",
              });
              userService.getUsers().then((data) => {
                this.Users = data.message;
              });
            });
          }
        });
    },
    ChangeStatus(userId, status) {
      this.swalWithBootstrapButtons
        .fire({
          title: "آیا مطمئن هستید ؟",
          text: "آیا از فعال / غیر فعال سازی مطمئن هستید ؟",
          icon: "warning",
          confirmButtonText: "تایید",
          cancelButtonText: "لغو",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            status = status == 1 ? "0" : "1";
            userService.ChangeStatus(userId, status).then((data) => {
              this.swalWithBootstrapButtons.fire({
                text: data.message,
                icon: data.err,
                confirmButtonText: "تایید",
              });
              userService.getUsers().then((data) => {
                this.Users = data.message;
              });
            });
          }
        });
    },
    AllStatus(type) {
      var selectedId = this.selected;
      if (selectedId.length) {
        this.swalWithBootstrapButtons
          .fire({
            title: "آیا مطمئن هستید ؟",
            text: "آیا از فعال / غیر فعال سازی مطمئن هستید ؟",
            icon: "warning",
            confirmButtonText: "تایید",
            cancelButtonText: "لغو",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              userService.AllChangeStatus(selectedId, type).then((data) => {
                this.swalWithBootstrapButtons.fire({
                  text: data.message,
                  icon: data.err,
                  confirmButtonText: "تایید",
                });
                userService.getUsers().then((data) => {
                  this.Users = data.message;
                });
              });
            }
          });
      }
    },
    AllDelete() {
      var selectedId = this.selected;
      if (selectedId.length) {
        this.swalWithBootstrapButtons
          .fire({
            title: "آیا مطمئن هستید ؟",
            text: "آیا از حذف مطمئن هستید ؟",
            icon: "warning",
            confirmButtonText: "تایید",
            cancelButtonText: "لغو",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              userService.AllDelete(selectedId).then((data) => {
                this.swalWithBootstrapButtons.fire({
                  text: data.message,
                  icon: data.err,
                  confirmButtonText: "تایید",
                });
                userService.getUsers().then((data) => {
                  this.Users = data.message;
                });
              });
            }
          });
      }
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const FullName = this.NewUser.fullname;
        const phone = this.NewUser.phone;
        const password = this.NewUser.password;
        const user_role = this.NewUser.user_role;
        userService
          .addUser({
            fullname: FullName,
            password: password,
            phone: phone,
            user_role: user_role,
          })
          .then((data) => {
            if (data.success) {
              userService.getUsers().then((newdata) => {
                this.Users = newdata.message;
              });
            }
            this.swalWithBootstrapButtons.fire({
              text: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodal = false;
      }
      this.submitted = false;
      this.NewUser = {};
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.NewUser = {};
    },
    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    selectAll: {
      get() {
        if (this.Users && this.Users.length > 0) {
          let allChecked = true;
          for (const user of this.Users) {
            if (!this.selected.includes(user.id)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }
          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.Users.forEach((user) => {
            checked.push(user.id);
          });
        }
        this.selected = checked;
      },
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="btn-group mr-1 mb-2" v-if="CheckAccessOnce('AllChangeStatus')">
                <b-dropdown variant="primary">
                  <template v-slot:button-content>
                    عملیات جمعی
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    @click="AllStatus('1')"
                    href="javascript: void(0);"
                    >فعالسازی وضعیت</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="AllStatus('0')"
                    href="javascript: void(0);"
                    >غیر فعالسازی وضعیت</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="AllDelete()"
                    href="javascript: void(0);"
                    >حذف</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <a
                href="javascript:void(0);"
                class="btn btn-success ml-2 mb-2"
                v-if="CheckAccessOnce('AddUser')"
                @click="showmodal = true"
              >
                <i class="mdi mdi-plus mr-2"></i> افزودن کاربر
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table
                class="table table-centered datatable dt-responsive nowrap"
              >
                <thead class="thead-light">
                  <tr>
                    <th class="text-center">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customercheck"
                          v-model="selectAll"
                        />
                        <label class="custom-control-label" for="customercheck"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th class="text-center">شناسه</th>
                    <th class="text-center">نام</th>
                    <th class="text-center">شماره همراه / نام کاربری</th>
                    <th class="text-center">
                      {{ $t("menuitems.users.list.role") }}
                    </th>
                    <th class="text-center">تاریخ ثبت</th>
                    <th class="text-center">آخرین ورود کاربر</th>
                    <th class="text-center">وضعیت</th>
                    <th class="text-center">عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in Users" :key="index">
                    <template v-if="Cuser_id !== item.id">
                      <td class="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            v-model="selected"
                            :value="item.id"
                            :id="`customercheck-${index}`"
                          />
                          <label
                            class="custom-control-label"
                            :for="`customercheck-${index}`"
                            >&nbsp;</label
                          >
                        </div>
                      </td>
                      <td class="text-center">{{ item.id }}</td>
                      <td class="text-center">{{ item.FullName }}</td>
                      <td class="text-center">{{ item.phone }}</td>
                      <td class="text-center">{{ item.user_role }}</td>
                      <td class="text-center">{{ item.register_time }}</td>
                      <td class="text-center">{{ item.l_login_time }}</td>
                      <td
                        class="text-center"
                        :class="
                          item.status == 1 ? 'text-success' : 'text-danger'
                        "
                      >
                        {{ item.status == 1 ? "فعال" : "غیرفعال" }}
                      </td>
                      <td>
                        <router-link
                          :to="'users/' + item.id"
                          class="side-nav-link-ref"
                        >
                          <a
                            href="javascript:void(0);"
                            class="mr-1 text-primary"
                            v-b-tooltip.hover
                            title="ویرایش"
                          >
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>
                        </router-link>
                        <a
                          href="javascript:void(0);"
                          class="mr-1 text-danger"
                          v-b-tooltip.hover
                          v-if="CheckAccessOnce('DeleteUser')"
                          @click="DeleteUser(item.id)"
                          title="حذف"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-info"
                          v-b-tooltip.hover
                          v-if="CheckAccessOnce('ChangeStatus')"
                          @click="ChangeStatus(item.id, item.status)"
                          title="فعال / غیرفعال"
                        >
                          <i class="mdi mdi-eye-outline font-size-18"></i>
                        </a>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="افزودن کاربر"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="fullname">نام</label>
          <input
            id="fullname"
            v-model="NewUser.fullname"
            type="text"
            class="form-control"
            placeholder="نام"
            :class="{ 'is-invalid': submitted && $v.NewUser.fullname.$error }"
          />
          <div
            v-if="submitted && !$v.NewUser.fullname.required"
            class="invalid-feedback"
          >
            نام الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="position">شماره همراه / نام کاربری</label>
          <input
            id="position"
            v-model="NewUser.phone"
            type="text"
            class="form-control"
            placeholder="شماره همراه / نام کاربری"
            :class="{ 'is-invalid': submitted && $v.NewUser.phone.$error }"
          />
          <div
            v-if="submitted && !$v.NewUser.phone.required"
            class="invalid-feedback"
          >
            شماره همراه / نام کاربری الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="position">رمز عبور</label>
          <input
            id="position"
            v-model="NewUser.password"
            type="password"
            class="form-control"
            placeholder="رمز عبور"
            :class="{ 'is-invalid': submitted && $v.NewUser.password.$error }"
          />
          <div
            v-if="submitted && !$v.NewUser.password.required"
            class="invalid-feedback"
          >
            رمز عبور الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="user_role">{{ $t("menuitems.users.list.roles") }}</label>
          <select
            id="user_role"
            v-model="NewUser.user_role"
            type="user_role"
            name="user_role"
            class="form-control custom-select"
            :class="{
              'is-invalid': submitted && $v.NewUser.user_role.$error,
            }"
          >
            <option
              v-for="(item, index) in userRoles"
              :key="index"
              :value="index"
            >
              {{ item }}
            </option>
          </select>
          <div
            v-if="submitted && $v.NewUser.user_role.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.NewUser.user_role.required"
              >نقش کاربر الزامی است</span
            >
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>