import Router from '../router'

export const userService = {
    getRoles,
    getAccess,
    updateRole,
    addUser,
    deleteUser,
    getUser,
    ChangePass,
    EditUser,
    ChangeStatus,
    AllChangeStatus,
    AllDelete,
    getUsers,
};

function getRoles() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/getrole`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getAccess(role) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "role": role,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/getaccess`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function updateRole(role,access) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "role": role,
            "access": access,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/updaterole`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addUser(data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "fullname": data.fullname,
            "password": data.password,
            "phone": data.phone,
            "user_role": data.user_role
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/adduser`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteUser(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/deleteuser`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getUser(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/getuser`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function ChangePass(id, password) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id,
            "password": password
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/changepass`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function EditUser(id, fullname, phone) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id,
            "fullname": fullname,
            "phone": phone
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/edituser`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function ChangeStatus(id, status) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id,
            "status": status
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/changestatus`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllChangeStatus(ids, status) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "ids": ids,
            "status": status
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/allchangestatus`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllDelete(ids) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "ids": ids
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users/alldelete`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getUsers() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL+`/users`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('auth');
                location.reload();
            }
            if (response.status === 500 || response.status === 503) {
                Router.push({
                    path: "/error-500",
                    redirect: {
                        name: "Error-500"
                    },
                });
            }
        }
        return data;
    });
}